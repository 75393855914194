import React, { useState } from "react";
import Footer from "./Footer";
import About from "./About";
import AboutUs from "./AboutUs";
import Hero from "./Hero";
import { motion, AnimatePresence } from "framer-motion";
import Contact from "./Contact";

function Home() {
    const [showAbout, setShowAbout] = useState(false);
    console.log("props", showAbout);
    return (
        <div>
            {/* Show Hero only when AboutUs is NOT shown */}
            {!showAbout && (
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 1.2, ease: "easeInOut" }}
                >
                    <Hero setShowAbout={setShowAbout} />
                </motion.div>
            )}

            {/* Conditionally Show AboutUs */}
            <AnimatePresence>
                {showAbout && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                    >
                        <AboutUs setShowAbout={setShowAbout} />
                    </motion.div>
                )}
            </AnimatePresence>

            {!showAbout && <About setShowAbout={setShowAbout} />}
            {!showAbout && <Contact />}
            <Footer />
        </div>
    );
}

export default Home;
