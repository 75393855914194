import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // To track the current route
import ArrowNav from '../assets/svg/arrowNav.svg';

const NavigationMenu = ({ setShowAbout }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation(); // To track current route

    const navItems = [
        { label: 'Home', href: '/', onClick: () => setShowAbout(false) },
        { label: 'About Us', href: '#', onClick: () => setShowAbout(true) },
        { label: 'Products', href: '/products' },
        { label: 'Services', href: '/services' },
    ];

    useEffect(() => {
        // Set activeIndex based on the current location
        const currentPath = location.pathname;
        const activeItemIndex = navItems.findIndex((item) => currentPath === item.href);
        setActiveIndex(activeItemIndex !== -1 ? activeItemIndex : 0);
    }, [location]);

    return (
        <div className="hidden lg:flex lg:items-center lg:gap-x-12 relative">
            {navItems.map((item, index) => (
                <div
                    key={item.label}
                    className="relative flex flex-col items-center"
                    onMouseEnter={() => setActiveIndex(index)}
                    onMouseLeave={() => setActiveIndex(activeIndex)}
                >
                    <a
                        href={item.href}
                        onClick={item.onClick}
                        className="text-2xl leading-6 text-white hover:text-green-600 transition-colors duration-200"
                    >
                        {item.label}
                    </a>
                    {/* Only show arrow if it's the active item */}
                    {activeIndex === index && (
                        <img
                            src={ArrowNav}
                            className="absolute bottom-[-10px] left-1/2 transform -translate-x-1/2 transition-all duration-300 slide-in"
                        />
                    )}
                </div>
            ))}

            {/* Contact Us Button */}
            <div className="pl-6">
                <a
                    href="contact"
                    className="px-6 py-3 bg-green-600 rounded-lg text-white text-lg font-semibold hover:bg-green-700 transition-colors duration-200"
                >
                    Contact Us
                </a>
            </div>

            <style jsx>{`
                @keyframes slideIn {
                    0% {
                        transform: translateX(-50%) translateY(-5px);
                        opacity: 0;
                    }
                    100% {
                        transform: translateX(-50%) translateY(0);
                        opacity: 1;
                    }
                }

                .slide-in {
                    animation: slideIn 0.3s ease-out;
                }
            `}</style>
        </div>
    );
};

export default NavigationMenu;
