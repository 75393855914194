import Header from "./Header";
import AboutUsBg from "../assets/jpg/ayurvedaBg2-min.jpg";

export default function AboutUs({ setShowAbout }) {
    return (
        <div
            style={{ backgroundImage: `url(${AboutUsBg})` }}
            className="bg-center lg:bg-cover lg:min-h-screen text-black flex flex-col"
        >
            {/* Header */}
            <Header setShowAbout={setShowAbout} />

            <div className="container mx-auto px-6 lg:px-6 py-12 flex flex-col items-center text-center">
                <h2 className="text-5xl font-extrabold mb-6 text-green-200 drop-shadow-lg">
                    About Us
                </h2>

                <div className="bg-white/10 p-6 lg:p-12 rounded-2xl shadow-lg backdrop-blur-md max-w-5xl">
                    <p className="text-lg lg:text-xl leading-relaxed text-white text-justify">
                        <span className="text-green-300 font-semibold text-3xl">Treaties:</span> Ayurveda is a science deeply rooted in classical texts. Everything from patient assessment, diagnosis, and treatment formulation to medicine preparation follows time-tested principles. This adherence ensures Ayurveda’s authenticity and relevance today. Aadisesha firmly believes that the wisdom of our great sages is eternal and offers solutions to modern health challenges. All our medicines are made using traditional Ayurvedic methods while addressing contemporary needs.
                    </p>

                    <p className="mt-6 text-lg lg:text-xl leading-relaxed text-white text-justify">
                        <span className="text-green-300 font-semibold text-3xl">Tradition:</span> Kerala has a rich history of using Ayurveda for healthcare. The traditional Keralite Ayurvedic approach is renowned for its strict adherence to time-honored practices. Aadisesha originates from the esteemed Edasseril Kuruppanmar lineage, a renowned Vaidya family from Kerala’s Kuttanad region. Our ancestors served as royal physicians to the Champakassery Kings of Ambalapuzha, leaving behind a legacy of Ayurvedic wisdom and expertise that continues to inspire our practices.
                    </p>

                    <p className="mt-6 text-lg lg:text-xl leading-relaxed text-white text-justify">
                        <span className="text-green-300 font-semibold text-3xl">Trust:</span> Trust plays a crucial role in Ayurvedic treatment. Even the most potent formulations cannot yield results without faith in the medicine. Aadisesha understands the importance of trust and ensures transparency in every stage of medicine manufacturing. From selecting the freshest raw materials to following strict quality control guidelines, we blend traditional Ayurvedic wisdom with modern innovations. This meticulous process guarantees the highest quality medicines, fostering trust among our patients.
                    </p>
                </div>

                {/* Button to go back to Home */}
                <button
                    onClick={() => setShowAbout(false)}
                    className="mt-8 px-8 py-4 bg-gradient-to-r from-green-500 to-green-700 text-white text-lg font-semibold rounded-full shadow-lg hover:scale-105 transition-all duration-300"
                >
                    Back to Home
                </button>
            </div>
        </div>
    );
}
