import HeroBg from '../assets/png/hero_bg.png';
import Header from './Header';

export default function Hero({ setShowAbout }) {


    return (
        <div>
            {/* Hero Section */}
            <div
                style={{ backgroundImage: `url(${HeroBg})` }}
                className="bg-center lg:bg-cover lg:min-h-screen text-white"
            >
                {/* Pass setShowAbout to Header */}
                <Header setShowAbout={setShowAbout} />

                <section className="py-12">
                    <div className="container mx-auto px-4 sm:px-8">
                        <h1 className="text-6xl sm:text-4xl lg:text-8xl font-bold text-center">
                            Aadisesha <br />Ayurvedics
                        </h1>

                        <p className="text-lg sm:text-2xl text-center mt-4">
                            Ayurveda is the Science of Life
                        </p>

                        <div className="mt-8 text-center">
                            <a
                                href="#"
                                className="px-6 py-3 bg-green-600 rounded-lg text-white text-3xl sm:text-2xl font-semibold hover:bg-green-700"
                            >
                                Discover Now
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
