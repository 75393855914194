import ContactBg from '../assets/jpg/contactBg-min.jpg'

export default function Contact() {
    return (
        <div>
            <div
                style={{ backgroundImage: `url(${ContactBg})` }}
                className="bg-center lg:bg-cover lg:min-h-screen text-white"
            >
                <section>

                </section>
            </div>

        </div>
    )
}